<template>
  <div id="main">
    <div class="content">
      <h3>简介：</h3>
      <p>2018年8月中国标准化研究院与湖南湘江新区管理委员会签署战略合作协议，中国标准化研究院资源与环境标准化研发基地（湖南）落户湖南，为中国标准化研究院全国部署的第四个基地，中南地区唯一基地。由湖南楚熵信息科技有限公司运营。</p>
      <h3>建设方向：</h3>
      <p>基地以中国标准化研究院、湖南楚熵信息科技有限公司和湖南永瑞标准化服务有限公司为依托，通过整合相关研发资源优势和人才优势，建成一个包括：标准及标准化综合咨询服务、能源托管服务相关技术标准的制定及研发创新技术孵化、设备与产品研发、试验检测及应用培训为一体的绿色技术开发与科技成果转化中心。实现标准、技术、信息、人才共享，探索节能节水等绿色生态文明建设标准化应用的创新思路，为产业的持续长远发展提供技术支持和研发保障。
      </p>
      <p>
        结合湖南省产业发展和空间规划布局，共同搭建资源与环境相关行业、领域的标准化研究与应用基地，包括但不限于能效、水效的科技创新研究与应用等；建立能效水效等绿色发展领域科技成果转换服务平台；启动区域重大科技工程，系统布局省内科技重大专项和战略性产业公关和绿色产业化创新项目；建立具备能效、水效、绿色产品等领域评估检测和标准符合性验证实验室或评估中心；推进绿色高新技术企业的国际化发展，并支持企业参与绿色发展等相关领域国内与国际标准化活动。
      </p>
      <h3>主要服务内容：</h3>
      <p>
        技术服务类：电机系统能效评价及提升；节能检测、评估、提升技术的开发与应用；绿色能源管控体系的设计、施工和运维；信息技术开发。
        标准化服务类：标准化托管；标准及标准化咨询；各级各类标准研制；企业标准化体系建设咨询；标准及标准化培训；标准化第三方评估；“标准化+”综合服务平台开发建设。
      </p>
      <img src="../../images/BaseIntroduction.jpg" alt="">
    </div>
  </div>
</template>

<script>
export default {
  name: "BaseIntroduction"
}
</script>

<style scoped>
#main{
  width: 1200px;
  margin: 110px auto 20px auto;
}
.content{
  margin-top: 20px;
}
h3{
  color: #4F81BD;
  margin-top: 25px;
}
p{
  line-height: 30px;
  text-indent: 2em;
}
.content img{
  width: 100%;
  margin-top: 25px;
}

</style>