<template>
  <div id="main">
    <h3>合作企业</h3>
    <el-carousel indicator-position="outside" height="300px">
      <el-carousel-item v-for=" (num,index1) in HZQY_Page">
        <ul class="list-view">
          <li v-for=" (item,index2) in HZQY_Maps" v-if="index2  < num*6 && index2>= index1*6 ">
            <img :src="item.url" alt="">
            <div class="no-img" v-if="item.url===null || item.url.trim()===''">{{item.title}}</div>
            <div class="curtain">
              <div class="curtain-footer">{{item.title}}</div>
            </div>
          </li>
        </ul>
      </el-carousel-item>
    </el-carousel>
    <h3 style="margin-top: 60px">战略合作</h3>
    <el-carousel indicator-position="outside" height="300px">
      <el-carousel-item v-for=" (num,index1) in ZLHZ_Page">
        <ul class="list-view">
          <li v-for=" (item,index2) in ZLHZ_Maps" v-if="index2  < num*6 && index2>= index1*6 ">
            <img :src="item.url" alt="">
            <div class="no-img" v-if="item.url===null || item.url.trim()===''">{{item.title}}</div>
            <div class="curtain">
              <div class="curtain-footer">{{item.title}}</div>
            </div>
          </li>
        </ul>
      </el-carousel-item>
    </el-carousel>
  </div>
</template>

<script>
export default {
  name: "CooperativePartner",
  data() {
    return {
      HZQY_Page: 0,
      ZLHZ_Page: 0,
      HZQY_Maps: [],
      ZLHZ_Maps: []
    }
  },
  created() {
    this.getData()
  },
  methods: {
    getData() {
      this.$http.get('RotationMap/queryByLabel/合作企业').then(({data}) => {
        this.HZQY_Maps = data.obj
        //计算有几页
        this.HZQY_Page = this.computePageSize(this.HZQY_Maps);
      })
      this.$http.get('RotationMap/queryByLabel/战略合作').then(({data}) => {
        this.ZLHZ_Maps = data.obj
        //计算有几页
        this.ZLHZ_Page = this.computePageSize(this.ZLHZ_Maps);
      })
    },
    /**
     *  按数组大小，每6个为一页计算数组分几页
     *
     **/
    computePageSize(array) {
      let pageSize = 0

      for (let i = 0; i < array.length; i++) {
        if (i % 6 === 0 && i !== 0) {
          pageSize += 1;
        }
        if (array.length === i + 1) {
          if (i % 6 > 0) {
            pageSize += 1;
          }
        }
      }
      return pageSize
    }
  }
}
</script>

<style scoped>
#main {
  width: 1200px;
  margin: 110px auto 20px auto;
}

.el-carousel {
}

h3 {
  text-align: center;
  margin-top: 20px;
}

.list-view li {
  display: inline-block;
  position: relative;
  width: 386px;
  height: 130px;
  padding: 20px;
  margin: 20px 20px 0 0;
  vertical-align: top;
  background-color: #F4F4F4;
  text-align: center;
}

.list-view li img {
  position: absolute;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  max-width: 346px;
  max-height: 120px;
  z-index: 1;
  margin: auto;
}

.list-view li:nth-child(3n+3) {
  margin-right: 0;
}

.curtain {
  position: absolute;
  top: 0;
  right: 0;
  width: 386px;
  height: 130px;
  cursor: pointer;
  z-index: 99;
}

.curtain-footer {
  position: absolute;
  bottom: 0;
  display: none;
  width: 100%;
  height: 25px;
  background-color: rgba(0, 0, 0, 0.3);
  color: #fff;
  line-height: 25px;
  text-align: center;
}

.curtain:hover .curtain-footer {
  display: block;
}

.no-img {
  position: absolute;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  width: 90%;
  height: 100px;
  z-index: 1;
  margin: auto;
  font-size: 20px;
  line-height: 100px;
}
</style>
