<template>
    <div id="main">
      <div class="content">
        <h3>1、参与相关标准制修订</h3>
        <h3>国家标准</h3>
        <p>（1）GB/T 40010-2021 合同能源管理服务评价技术导则 （制订）</p>
        <p>（2）GB/T 40063-2021  工业企业能源管控中心建设指南（制订）</p>
        <p>（3）GB/T 40064-2021 节能技术评价导则（制订）</p>
        <p>（4）GB/T 39965-2021 节能量前评估计算方法（制订）</p>
        <p>（5）GB/T 23331-2020 能源管理体系 要求及使用指南（制订）</p>
        <p>（6）GB/T 38692-2020 用能单位能耗在线监测技术要求（制订）</p>
        <p>（7）GB 28736-2019 电焊机能效限定值及能效等级（修订）</p>
        <p>（8）（计划号20140609-T-469）电机系统能效评价（制订）</p>
        <img src="../../images/标准文件1.png" alt="">
        <img src="../../images/标准文件2.png" alt="">
        <img src="../../images/标准文件3.png" alt="">
        <img src="../../images/标准文件4.png" alt="">
        <img src="../../images/标准文件5.png" alt="">
        <img src="../../images/标准文件6.png" alt="">
        <img src="../../images/标准文件7.png" alt="">
        <h3>国家级团体标准</h3>
        <p>（1）T/CAQP 015-2020、T/ESF 001-2020《“领跑者“标准编制通则》</p>
        <p>（2）T/CSTE 0055—2020 《“领跑者”标准评价要求 柔性防火电缆》</p>
        <p>（3）T/CECA-G 0086—2020《“领跑者”标准评价要求 电力变压器》</p>
        <p>（4）T/CECA-G 0087—2020《“领跑者”标准评价要求 工业锅炉》</p>
        <p>（5）T/CECA-G 0088—2020《“领跑者”标准评价要求 交流焊机》</p>
        <p>（6）T/CECA-G 0089—2020《“领跑者”标准评价要求 离心鼓风机》</p>
        <p>（7）T/CECA-G 0067—2020《“领跑者”标准评价要求 直流焊机》</p>
        <p>（8）T/CECA-G 0075—2020《“领跑者”标准评价要求 电弧切割机》</p>
        <p>（9）T/CECA-G 0073—2020《“领跑者”标准评价要求 电阻焊机》</p>
        <p>（10）T/CECA-G 0072—2020《“领跑者”标准评价要求 焊接用辅具》</p>
        <p>（11）T/CECA-G 0070—2020《“领跑者”标准评价要求 埋弧焊机》</p>
        <p>（12）T/CECA-G 0069—2020《“领跑者”标准评价要求 气体保护焊机》</p>
        <p>（13）T/CSTE 0028—2021《绿色设计产品评价技术规范 车用磷酸铁锂动力电池梯次利用产品》</p>
        <p>（14）T/CSTE 0030—2021《绿色设计产品评价技术规范  车载式混凝土泵》</p>
        <p>（15）T/CSTE 0029—2021《绿色设计产品评价技术规范  新能源搅拌运输车》</p>
        <p>（16）T/SSEA 0139—2021 T/CSTE 0069—2021《“领跑者”标准评价要求 汽车用冷连轧热冲压钢板及钢带》</p>
        <p>（17）T/SSEA 0140—2021 T/CSTE 0070—2021《“领跑者”标准评价要求 连续热浸镀铝硅合金镀层钢板及钢带》</p>
        <p>（18）T/CSTE0065-2021《饲用缓控释包膜制剂技术通则》</p>
        <p>（19）T/CSTE0066-2021 《饲用微囊脂肪粉生产技术规范》</p>
        <p>（20）T/CSTE 0057—2021《绿色设计产品评价技术规范涂覆织物类防雨服》</p>
        <p>（21）T/CSTE 0136—2021《绿色设计产品评价技术规范职业鞋靴》</p>
        <p>（22）T/CSTE 0069-2021《污泥干化碳化处理一体化装备》</p>

        <h3>2、其他项目情况</h3>
        <p>（1）2018年承接湖南省科技厅长株潭重大创新类绿色建筑与基础设施“标准化+”智慧水效能效监控平台建设项目。</p>
        <p>（2）2019年5月，与中国标准化研究院签订泵机系统能效评价和验证技术调研服务合同。</p>
        <p>（3）2019年7月，与湖南省计量院签订绿色泵机系统能效评价方法研究服务合同。</p>
        <p>（4）预计2020年1月-2022年12月期间，完成科技部发布国家重点研发计划项目--《先进高效中小型三相异步电机技术方案和能效标准研究》。</p>
        <p>（5）2020年3月，与湖南省计量院签订签订国内外绿色电动车能效评价</p>
        <p>（6）2020年7月15日，楚熵中标2020年湖南省工业节能诊断服务工作服务机构，并对11家企业开展了节能诊断工作。</p>
        <p>（7）2020年10月26日，楚熵中标国网湖南省电力有限公司2020年第六次工程及服务项目公开竞争性谈判--2020年长沙供电公司湖南省城市居住区供配电设施建设的咨询服务项目。</p>
        <p>（8）楚熵入选2020年企业标准“领跑者”评估机构（第一批），编制评估方案，开展柔性防火电缆产品的企业标准“领跑者”评价工作。</p>
        <p>（9）2021年企业标准“领跑者”评估机构（第一批），编制评估方案，开展钓鱼浮标产品的企业标准“领跑者”评价工作。</p>

        <h3>3、获得知识产权及荣誉情况</h3>
        <table cellspacing="1">
          <th>序号</th><th>名称</th><th>登记号</th><th>发布日期</th>
          <tr>
            <td>1</td>
            <td>能效水效物联网泵机检测仪软件</td>
            <td>2021SR1048233</td>
            <td>2021-7-16</td>
          </tr>
          <tr>
            <td>2</td>
            <td>能效水效物联网电机检测仪软件</td>
            <td>2021SR1045791</td>
            <td>2021-7-15</td>
          </tr>
          <tr>
            <td>3</td>
            <td>能效水效物联网电机系统检测平台</td>
            <td>2021SR1043622</td>
            <td>2021-7-15</td>
          </tr>
          <tr>
            <td>4</td>
            <td>能效水效物联网空压机检测仪软件</td>
            <td>2021SR1045559</td>
            <td>2021-7-15</td>
          </tr>
          <tr>
            <td>5</td>
            <td>绿色建筑能效水效监测平台</td>
            <td>2021SR1049729</td>
            <td>2021-7-16</td>
          </tr>
          <tr>
            <td>6</td>
            <td>能效水效物联网通风机检测仪软件</td>
            <td>2021SR1048681</td>
            <td>2021-7-16</td>
          </tr>
        </table>

        <img src="../../images/证书1.jpg" alt="">
        <img src="../../images/证书2.jpg" alt="">
        <img src="../../images/证书3.jpg" alt="">
        <img src="../../images/证书4.jpg" alt="">
        <img src="../../images/证书5.jpg" alt="">
        <img src="../../images/证书6.jpg" alt="">
        <img src="../../images/证书7.jpg" alt="">
        <img src="../../images/证书8.png" alt="">
        <img src="../../images/证书9.jpg" alt="">
      </div>
    </div>
</template>

<script>
export default {
  name: "AchievementsAndHonors"
}
</script>

<style scoped>
#main{
  width: 1200px;
  margin: 110px auto 20px auto;
}
h3{
  margin-top: 20px;
}
p{
  margin-top: 10px;
}
table{
  width: 650px;
  text-align: center;
  line-height: 40px;
  background: black;
  margin-top: 20px;
}
table td,table th{
  background-color: #fff;
}
img{
  width: 580px;
  margin-top: 20px;
  margin-right: 20px;
}
</style>