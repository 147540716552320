<template>
  <div id="main">
    <el-tabs v-model="activeName" @tab-click="handleClick">
      <el-tab-pane label="标准新闻" name="first">
        <component :is="GreenDesignProducts"/>
      </el-tab-pane>
      <el-tab-pane label="标准解读" name="second">
        <component :is="GreenPark"/>
      </el-tab-pane>
      <el-tab-pane label="标准征集" name="third">
        <component :is="GreenFactory"/>
      </el-tab-pane>
    </el-tabs>
  </div>
</template>

<script>
export default {
  name: "NewsInformation",
  data(){
    return{
      activeName:'first'
    }
  }
}
</script>

<style scoped>
#main{
  width: 1200px;
  margin: 110px auto 20px auto;
}
</style>